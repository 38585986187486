.flex {
  display: flex;

  &-direction {
    &-row {
      flex-direction: row;
    }
    &-column {
      flex-direction: column;
    }
  }

  &-jc {
    &-space-between {
      justify-content: space-between;
    }
    &-start {
        justify-content: start;
    }
    &-end {
      justify-content: end;
    }
  }

  &-ai {
      &-start {
          align-items: flex-start;
      }
      &-end {
          align-items: flex-end;
      }
  }
}

.align-self {
    &--end {
        align-self: flex-end;
    }
    &--start {
        align-self: flex-start;
    }
}