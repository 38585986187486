$background-color: #ffffff;
$text-grey-color: #949494;
$legend-background-color: #fafafb;
$legend-text-color: #000000;
$legent-background-color-total: #f7f7f7;
$legent-background-color-used: #e4fcda;
$legent-background-color-available: #e8efff;
$legent-background-color-overdue: #ffebec;

.mini-donut-pie {
  position: relative;
  background-color: $background-color;

  .plate {
    background-color: $legend-background-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8rem;
    height: 8rem;
    -webkit-border-radius: 6.75rem;
    -moz-border-radius: 6.75rem;
    border-radius: 6.75rem;
  }

  .plate + svg {
    position: absolute;
  }

  .donut-wrapper {
    width: 14rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text-grey {
      color: $text-grey-color;
    }
  }

  .donut-unavailable {
    &__content {
      opacity: 0.1;
    }

    &__message{
      font-size: 1.5rem;
      color: #000;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.legend-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.donut-legend {
  flex-basis: 9.8rem;
  height: 7.5rem;
  font-size: 12px;
  color: $legend-text-color;
  background-color: $legend-background-color;
  margin: 0.5rem 0rem 0rem 0.5rem;
  border-radius: 0.5rem;
  padding: 0.7rem;
  cursor: pointer;

  h4 {
    margin: unset;
  }

  &.blur {
    opacity: 0.45;
  }

  &.active {
    opacity: 1;
    transition: background-color 0.5s ease-out;
    &.total {
      background-color: $legent-background-color-total;
    }
    &.used {
      background-color: $legent-background-color-used;
    }
    &.available {
      background-color: $legent-background-color-available;
    }
    &.overdue {
      background-color: $legent-background-color-overdue;
    }
  }
}
