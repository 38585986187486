.kebab {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 0.938rem;
  margin: 0 0.313rem;
  padding: 0.313rem;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.5s ease-in-out;
  transition: transform 0.3s ease-out;

  &--loading &__amounts {
    opacity: 0.2;
  }
  &--blurred &__amounts {
    opacity: 0.2;
  }

  &:not(.kebab--blurred) {
    cursor: pointer;
    z-index: 5;
  }

  &--blurred {
    color: rgba(0, 0, 0, 0.15);
    img {
      opacity: 0.5;
    }
  }

  &:hover:not(.kebab--blurred) {
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.313rem 0.938rem;
    transform: scale(1.05);
    img {
      transition: opacity 0.3s ease-out;
      opacity: 1;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem;
    padding-bottom: 0.313rem;

    &__title {
      font-size: 1.5rem;
      font-weight: bold;
    }
    &__icon {
      display: flex;
      img {
        transform: scale(1.54);
        margin: auto;
      }
      &__blurred {
        img {
          filter: grayscale(100%);
          opacity: 0.3;
        }
      }
    }
  }
  &__amounts {
    padding: 1.25rem 0.625rem;
    &__item {
      &:first-of-type {
        padding-bottom: 0.625rem;
      }
      &__amount {
        font-size: 1.2rem;
        color: #2b63f4;
      }
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &__description {
        font-size: 0.9rem;
        word-break: break-all;
      }
    }
  }

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  &__chart {
    position: relative;
    margin: 0.188rem;
    margin-top: auto;
    border-radius: 0.625rem;
    height: 25rem;
    overflow: hidden;

    &__spinner {
      position: absolute;
      width: 3.125rem;
      height: 3.125rem;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
    }

    &--border {
      border: 1px solid gray;
    }
  }

  &__error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: #000000;
      font-size: 1.2rem;
      word-break: break-word;
      padding: 0 0.625rem;
    }
  }
}
.no-product {
  display: flex;
  margin-top: 4rem;
  padding: 0 1rem;
}
