.view-disbursements-modal {
  &__list {
    .empty {
      margin-top: unset;
      margin-bottom: 0.5rem;
    }
    margin-bottom: 2.5rem;
    @include default-bottom-border();

    &.list li {
      @include default-bottom-border();
      justify-content: initial;

      span {
        padding-right: 1rem;
      }
    }
  }
}
