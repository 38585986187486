// Detail view.
body {
  .contractDetailView {
    margin-top: 1.1875rem;

    h2 {
      font-size: 1.3125rem;
      font-weight: normal;

      .invoiceNo {
        margin-right: 1rem;
      }

      .invoiceStatus {
        margin-left: 1rem;
      }
    }

    h3 {
      margin-left: 2.025rem;
      margin-bottom: 1.1rem;
    }

    p label {
      position: absolute;
      left: 6.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .FeesList {
      th:first-child {
        width: 13.25rem;
      }

      th:nth-child(2) {
        width: 7rem;
      }

      td:nth-child(2) {
        padding-right: 0.625rem;
      }
    }

    .TransactionList {
      padding: 0 2rem 0 2rem;

      th {
        font-size: 0.9375rem;
        font-weight: normal;
        padding-left: 0;
      }

      a,
      .ant-btn-link {
        display: inline-block;
        width: 1rem;
        height: 0.9375rem;
        overflow: hidden;
        text-indent: 9999rem;
        background-image: url("./../../icons/icon_download.svg");
        background-repeat: no-repeat;
        background-position: top center;
        transform: rotate(180deg);
      }

      .ant-btn-link {
        margin-right: 1rem;
        height: 0.9375rem;
        padding: unset;
        line-height: 1.5715;
      }

      tfoot {
        tr:last-child:not(.empty) {
          border-bottom: unset;
        }

        .textAlignRight {
          td {
            padding-right: 0.625rem;

            &:first-child {
              padding-right: 0;
            }
          }
        }
      }

      .linkButton {
        button {
          margin-top: 0.188rem;
        }
      }
    }

    .smallCol {
      width: 7rem;
      padding-right: 0.625rem;
      padding-left: 1rem;
    }

    tfoot,
    tbody {
      label {
        font-size: inherit;
      }
    }

    .buttons {
      padding: 2rem 0;

      div {
        float: left;

        button {
          padding-left: 2.8rem;
          padding-right: 2.8rem;

          span {
            margin-top: -0.125rem;
          }
        }

        &:last-child {
          float: right;

          button {
            margin-left: 1rem;
          }
        }
      }
    }

    > .invoiceStatusCarpet {
      padding-top: 1.625rem;

      padding-left: 2rem;
      padding-right: 2rem;

      &::before {
        display: block;
        width: 100%;
        height: 22.75rem;
        content: "";
        background-color: $color-contract-submitted-bg;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 3.1875rem;
        border-right: 0.5rem $color-contract-submitted-border solid;
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
      }

      &.block.invoiceStatus-submitted::before {
        background-color: $bg-invoice-status-submitted;
        border-right-color: $color-border-invoice-status-submitted;
      }

      &.block.invoiceStatus-notsubmitted::before {
        background-color: $bg-invoice-status-notsubmitted;
        border-right-color: $color-border-invoice-status-notsubmitted;
      }

      &.block.invoiceStatus-paid::before {
        background-color: $bg-invoice-status-paid;
        border-right-color: $color-border-invoice-status-paid;
      }

      &.block.invoiceStatus-overdue::before {
        background-color: $bg-invoice-status-overdue;
        border-right-color: $color-border-invoice-status-overdue;
      }

      &.block.invoiceStatus-rejected::before {
        background-color: $bg-invoice-status-rejected;
        border-right-color: $color-border-invoice-status-rejected;
      }

      &.block.invoiceStatus-financed::before {
        background-color: $bg-invoice-status-financed;
        border-right-color: $color-border-invoice-status-financed;
      }

      &.block.invoiceStatus-partiallypaid::before {
        background-color: $bg-invoice-status-partiallypaid;
        border-right-color: $color-border-invoice-status-partiallypaid;
      }

      &.block.invoiceStatus-closed::before {
        background-color: $bg-invoice-status-closed;
        border-right-color: $color-border-invoice-status-closed;
      }

      &.block.invoiceStatus-transferred::before {
        background-color: $bg-invoice-status-transferred;
        border-right-color: $color-border-invoice-status-transferred;
      }
    }

    .contractTitleAndInfo {
      margin-left: 2rem;
    }

    .contractData {
      margin-top: 1.4375rem;
      background-color: $color-background;
      position: relative;
      padding: 2rem;
      margin-bottom: 1.5rem;
      border-radius: 0.625rem;
      padding-top: 1.625rem;

      @include box-shaddow-thin;

      p {
        color: $color-txt-black;
        font-size: 1.0625rem;
        position: relative;
      }

      .verificationStatus {
        padding-left: 2rem;
        padding-top: 1.1rem;
        border-left: 1px solid $color-invoice-border;

        .verificationTitle {
          font-size: 1.313rem;
          margin-bottom: 1.1rem;
        }

        p.invoiceVerificationEmail {
          word-break: break-word;
        }

        .waiting {
          color: $color-border-invoice-status-submitted;
        }
        .accepted {
          color: $color-border-invoice-status-paid;
        }
        .notApplicable {
          color: $color-border-invoice-status-rejected;
        }
        .rejected {
          color: $color-border-invoice-status-rejected;
        }
      }
    }
  }
}
