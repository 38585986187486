.dashboard-container {
  .document-sign {
    position: absolute;
    right: 13.625rem;
    top: -5rem;
    padding: 0.8rem 0.5rem;
  }

  .document-sign {
    right: 13rem;
  }

  button.loadMore {
    padding: 0.875rem 2.0625rem;
    height: auto;
  }

  &.loan {
    .donutData {
      .link {
        padding: unset;
        margin-top: 1.3rem;
        span {
          font-size: 0.8125rem;
        }
      }
    }

    .block-upcomingPayments {
      table {
        tbody {
          tr {
            cursor: default;
          }
        }
      }
    }
  }

  .ContractSelector {
    cursor: pointer;
    padding: $default-section-padding;
    margin: 1.175rem 0 0 0;

    h4 {
      display: none;
    }

    .contractFilterSingle {
      margin: 0;
    }
  }

  .contractsSelectMenu {
    top: -0.125rem;
  }

  .block {
    margin-bottom: 0.9375rem;
    .buttonContainer {
      text-align: center;
    }

    .buttonContainerBottom {
      padding-bottom: 2rem;
    }
  }

  .block-wave-chart {
    padding: 0;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .ant-table-wrapper {
    padding: 2rem 0;
  }

  button {
    font-size: 0.9375rem;
  }

  .invoiceStatusCell {
    border-bottom: unset;
    width: 0.5625rem;
    padding-right: 0;
  }

  .ant-table-wrapper:not(.tableBelowWave) {
    .ant-table-cell {
      &.paymentStatus:after {
        display: block;
        position: absolute;
        width: 0.5rem;
        content: "";
        height: 100%;
        top: -0.0625rem;
        border-right: 0.5rem solid $color-invoice-default;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        right: 0;
      }

      &.paymentStatus-submitted:after {
        border-right-color: $color-is-submitted;
      }
      &.paymentStatus-rejected:after {
        border-right-color: $color-is-rejected;
      }
      &.paymentStatus-financed:after {
        border-right-color: $color-is-financed;
      }
      &.paymentStatus-partiallypaid:after {
        border-right-color: $color-is-partiallypaid;
      }
      &.paymentStatus-paid:after {
        border-right-color: $color-is-paid;
      }
      &.paymentStatus-closed:after {
        border-right-color: $color-is-closed;
      }
      &.paymentStatus-transferred:after {
        border-right-color: $color-is-transferred;
      }
      &.paymentStatus-overdue:after {
        border-right-color: $color-is-overdue;
      }

      .ant-row {
        margin-bottom: 1rem;

        .ant-col {
          background-color: $color-background;
          padding: 3rem 0;

          h3 {
            margin-left: 4rem;
          }

          .ant-table-wrapper {
            padding: 1rem 4rem;
            padding-bottom: 3rem;
            margin: 0;
          }

          .buttonContainer {
            text-align: center;
          }
        }

        &:first-child {
          .ant-col {
            padding: unset;
          }
        }
      }
    }
  }

  .block-upcomingPayments {
    background-color: #fff;
    padding: 4rem 4rem 4rem 5.5rem;

    > h3 {
      padding-left: 0;
      padding-right: 0;
    }

    .invoiceStatusTxt {
      text-transform: capitalize;
    }
  }

  .ant-table {
    thead {
      tr th:first-child {
        padding: 1rem 1rem 1rem 0;
      }
      tr th {
        padding: 1rem;
      }
    }
    tbody {
      tr td:first-child {
        padding: 1rem 1rem 1rem 0;
      }
      tr td {
        padding: 1rem;
      }
    }
  }

  .general {
    .table-row-cell {
      &__companyName {
        word-break: break-word;
        max-width: 11.5rem;
        min-width: 9.5rem;
      }
      &__contractNumber {
        max-width: 10rem;
        min-width: 8rem;
      }
      &__billNumber {
        max-width: 8rem;
        min-width: 6rem;
      }
      &__billType {
        max-width: 9rem;
        min-width: 7rem;
      }
      &__dueDate {
        min-width: 7rem;
      }
      &__openAmount {
        max-width: 8rem;
        min-width: 6rem;
      }
      &__status {
        min-width: 5rem;
        max-width: 8rem;
      }
    }
  }

  .factoring {
    .table-row-cell {
      &__companyName {
        word-break: break-word;
        max-width: 11.5rem;
        min-width: 7rem;
      }
      &__invoiceNumber {
        max-width: 6rem;
        min-width: 5rem;
      }
      &__billType {
        max-width: 8rem;
        min-width: 6rem;
      }
      &__dueDate {
        max-width: 6rem;
        min-width: 4rem;
      }
      &__amountOutstanding {
        max-width: 8rem;
        min-width: 6rem;
      }
      &__billStatus {
        min-width: 3rem;
        max-width: 5rem;
      }
    }
  }
}
