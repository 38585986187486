$color-parties-dropdown-scrollbar-track: #efefef;
$color-parties-dropdown-scrollbar-thumb: #dbdbdb;
$color-table-row-hover-bgcolor: #f0f4fe;
$color-sme-blue: #2869f1;
$color-sme-blue-shade-40: #a9c3f9;
$color-sme-blue-shade-10: #eaf0fe;
$color-g4r-red: #ed2f59;
$color-g4r-red-shade: #f0587a;
$color-sme-input-icons: #2b63f4;
$color-background: #fff;
$color-white-txt: #fff;
$color-labels-txt: #b0b0b0;
$color-hover-bg: #f5f5f5;
$color-border-right: #e6e6e6;
$color-labels-active: #000;
$color-txt-black: #000;
$color-txt-black-notfound: #262626;
$color-txt-light: #808080;
$color-input-border-not-active: #cccccc;
$color-tables-border-not-active: #eeeeee;
$color-background-block: #f9f9f9;
$color-border-user-menu: #e8e8e8;
$color-border-list: #d6d3d3;
$color-txt-user-menu: #c7c7c7;
$color-txt-user-menu-btn: #b0bffa;
$color-invoice-not-submitted: #ececec;
$color-invoice-submitted: #9fadff;
$color-invoice-overdue: #ff9fa4;
$color-invoice-verification: #ffd49f;
$color-invoice-rejected: #979797;
$color-invoice-paid: #dbf9c3;
$color-invoice-default: #fff;
$color-menu-item-not-active: #aabaf6;
$color-txt-confirmed: #66cc66;
$color-contact-icon: #d1d1d1;
$color-contract-paid-bg: #eafbe9;
$color-contract-paid-txt: #9ae79a;
$color-contract-paid-border: #a2eaa2;
$color-contract-submitted-bg: #eff1ff;
$color-contract-submitted-txt: #9eacff;
$color-contract-submitted-border: #a6b3ff;
$color-contract-overdue-bg: #ffefef;
$color-contract-overdue-txt: #ff6666;
$color-upload-error: #ff6666;
$color-contract-overdue-border: #ffa7aa;
$color-contract-notsubmitted-bg: #f4f4f4;
$color-contract-notsubmitted-txt: #afafaf;
$color-contract-notsubmitted-border: #ededed;
$color-button-add-buyer: #f0f5fe;
$color-pagination-border: #ccd9fc;
$color-pagination-active-txt: #4c7bf6;
$color-switch-cheched: #2b64f5;
$color-switch-circle: #e9f0fe;
$color-login-tab-active: #eaf0fe;
$color-login-tab: #c6d3fb;
$color-cancel-button: #bfd0fc;
$color-separator: #e7e7e7;
$color-close: #ff9ea3;
$color-statement-paid: #dbfac4;
$color-statement-unpaid: #ff9fa5;
$color-donut-bg: #fafafa;
$color-donut-p: #b6b6b6;
$color-report-checkbox-border: #ccd5f9;
$color-report-checkbox-checked-bg: #f0f4fe;
$color-link: #80a6f7;
$color-error: #ff002a;
$color-lightgray: #cdcdcd;
$color-tab-not-active: #edf1fe;
$color-tab-light: lightgrey;
$color-secondary-text: #b8b8b8;
$color-file-item: #f8f8f8;
$color-no-items: #bebebe;
$bg-donut-total: #eff3fe;
$bg-donut-used: #e4ebfe;
$bg-donut-shaded: #fdfdfd;
$bg-donut-avl: #eef3fe;
$bg-donut-overdue: #ffe8e8;
$donut_total_focused: #dfe7fe;
$donut_not_hovered_bite: #f9f9f9;
$donut_overdue_used_hover: #2c64f5;
$color-is-submitted: #f2d8a5;
$color-is-not-submitted: #ececec;
$color-is-paid: #e3fbc7;
$color-is-overdue: #e7a2a6;
$color-is-rejected: #979797;
$color-is-financed: #a8a7fb;
$color-is-partiallypaid: #f2d8a5;
$color-is-closed: #979797;
$color-is-transferred: #979797;
$color-main-background: #f3f3f3;
$color-invoice-border: #e5e5e5;
$bg-invoice-status-submitted: #fff5eb;
$color-border-invoice-status-submitted: #ffcc99;
$bg-invoice-status-notsubmitted: #f3f3f3;
$color-border-invoice-status-notsubmitted: #ececec;
$bg-invoice-status-paid: #e9fae9;
$color-border-invoice-status-paid: #85d685;
$bg-invoice-status-overdue: #ffefef;
$color-border-invoice-status-overdue: #ff6666;
$bg-invoice-status-rejected: #eaeaea;
$color-border-invoice-status-rejected: #979797;
$bg-invoice-status-financed: #eff1ff;
$color-border-invoice-status-financed: #a5b2ff;
$bg-invoice-status-partiallypaid: #fff5eb;
$color-border-invoice-status-partiallypaid: #ffcc99;
$bg-invoice-status-closed: #fcf7de;
$color-border-invoice-status-closed: #f2d75a;
$bg-invoice-status-transferred: #eaeaea;
$color-border-invoice-status-transferred: #979797;
$panel-footer-color: #ebf1fe;
$table-table-wrapper-padding: 4rem;
$color-contract-folder-icon: #e8e8e8;
$color-contract-file-bg: #f9f9f9;
$tiny-text: 0.6875rem;
$small-text: 0.9375rem;
$medium-text: 1.5625rem;
$color-hover-contract-selector: #e9f0fe;
$bg-personal-assistance: #ffffff;
$color-personal-assistance-question: #b3b3b3;
$bg-info-svg: #adc4ff;
$box-shadow-personal-assistance: 0 0.1125rem 0.475rem 0 #c2c2c2;
$bg-personal-assistance-item: #f8f8f8;
$bg-personal-assistance-faq: #eaf0ff;
$bg-contract-not-selected: #ffffff;
$bg-contract-selected: #e9f0fe;
$bg-input-slider: #f8f8f8;
$bg-input-slider-rail-inactive: #d2d2d2;
$color-hover-donutpie-total: #F7F7F7;
$color-hover-donutpie-used : #E8EFFF;
$color-hover-donutpie-overdue : #FFEBEC;
$color-hover-donutpie-available : #E4FCDA;

//TODO merge to single
$color-border-bill-status-paid: #84D684;
$color-border-bill-status-overdue: #FF6464;
$color-border-bill-status-unpaid: #FFBC79;
$color-border-bill-status-partially-paid: #84D6BA;
$color-drag-highlighter: #52b3e4;
$color-button-file-upload: #dddedf;
$color-icon-minus: #ed2f59;
$color-hover-donutpie-total: #f7f7f7;
$color-hover-donutpie-used: #e8efff;
$color-hover-donutpie-overdue: #ffebec;
$color-hover-donutpie-available: #e4fcda;
$color-payment-legal-info: #a7a7a7;

$default-section-padding: 0.65rem;

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"), url("./../fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"), url("./../fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"), url("./../fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Light"), url("./../fonts/MyriadPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold"), url("./../fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold Italic"), url("./../fonts/MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed"), url("./../fonts/MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold"), url("./../fonts/MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Italic"), url("./../fonts/MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed Italic"), url("./../fonts/MYRIADPRO-BOLDCONDIT.woff") format("woff");
}

@mixin fontdef-woff($FontPath, $FontName, $FontVersion: "1.0.0", $FontType: "Regular") {
  src: url("#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff2?v=#{$FontVersion}") format("woff2"),
    url("#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff?v=#{$FontVersion}") format("woff");
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff("./../fonts", "Roboto", "2.137", "Regular");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff("./../fonts", "Roboto-Medium", "2.137", "Regular");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff("./../fonts", "Roboto-Bold", "2.137", "Regular");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff("./../fonts", "Roboto-Light", "2.137", "Regular");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff("./../fonts", "Roboto", "2.137", "Regular");
  font-weight: normal;
  font-style: normal;
}
/* END Regular */