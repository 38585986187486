.ant-input {
  border-left: unset;
  border-top: unset;
  border-right: unset;
  color: unset;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: unset;
  box-shadow: unset;
  -webkit-box-shadow: unset;
  outline: none;
  padding-left: 0;
  padding-right: 0;
  background-image: none;

  &::placeholder {
    color: $color-txt-black;
  }

  &:hover {
    border-bottom-color: $color-input-border-not-active;
  }
}

.ant-input-affix-wrapper {
  border-bottom: unset;
}

.ant-input:focus,
.ant-input-focused {
  border-color: $color-txt-black;
}

.ant-input-affix-wrapper > input.ant-input {
  border-bottom: 0.0625rem solid $color-input-border-not-active;
}
