.paid-modal {
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 0.5rem;
  }

  .payment-success {
    display: block;
    margin: 0 auto;
  }

  button {
    display: block;
    margin: 0 auto;

    &.ant-btn-link {
      color: $color-switch-cheched;
    }
  }

  p.payment-message {
    font-size: 1.125rem;
    margin-top: 2rem;
    text-align: center;
  }

  .payment-thank-you-text {
    text-align: center;
    margin-bottom: 2rem;
  }
}
