.collaterals {
  float: right;
}

.view-collaterals-modal {
  h4 {
    padding-bottom: 0.625rem;
    @include default-bottom-border();
  }
  &__list {
    .empty {
      margin-top: unset;
      margin-bottom: 0.5rem;
    }
    margin-bottom: 2.5rem;
    @include default-bottom-border();
  }
}
