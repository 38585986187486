.edit-buyer {
  .ant-modal-content {
    width: auto;

    .ant-modal-footer::after {
      content: none;
    }
  }

  &__new-limit {
    margin: 0;
  }

  &__column {
    &:first-child {
      border-right: 0.1rem solid rgba($color: $color-txt-black, $alpha: 0.1);
    }

    &:last-child {
      padding-left: 3rem;
    }

    &--new-limit {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__box {
      margin-bottom: 1.5rem;

      label {
        font-size: 0.8125rem;
      }

      p {
        font-size: 1rem;
        margin: 0;
        color: $color-txt-black;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__address-row {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
  }
}
