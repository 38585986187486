.DonutPie {
  padding: $default-section-padding;
  background-color: $color-background;
  margin-bottom: 0.9375rem;
  border-radius: 0.625rem;
  transition-delay: 0.1s;

  .legend-cont {
    margin: 0.1rem;
    &.active {
      &.total {
        background-color: $color-hover-donutpie-total;
      }
      &.used {
        background-color: $color-hover-donutpie-used;
      }
      &.available {
        background-color: $color-hover-donutpie-available;
      }
      &.overdue {
        background-color: $color-hover-donutpie-overdue;
      }

      .ant-col {
        background-color: transparent;
      }
    }

    .list {
      display: flex;
      flex-direction: row;

      .list-item {
        font-size: 0.8125rem;
        margin: 0;
        padding: 0;
        color: #b6b6b6;
        line-height: 1rem;

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  .blur {
    opacity: 0.45;
  }
  .active {
    opacity: 1;
    transition: background-color 0.5s ease-out;
  }

  .plate {
    background-color: $color-donut-bg;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12.5rem;
    height: 12.5rem;
    -webkit-border-radius: 6.75rem;
    -moz-border-radius: 6.75rem;
    border-radius: 6.75rem;
  }

  .plate + svg {
    position: absolute;
  }

  .donut-wrapper {
    width: 20rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text-grey {
      color: #949494;
    }
  }

  .VictoryContainer {
    svg {
      g {
        path {
          transition: background-color 0.5s ease-out;
          filter: unset !important;
        }
      }
    }
    text {
      display: none;
    }
  }

  .ant-row {
    margin-bottom: 0;
  }

  .donutData {
    color: $color-txt-black;

    &:last-child {
      > .ant-col {
        padding-top: 0;
      }
    }
    > .ant-col {
      padding: 0.8rem 1rem;
      max-width: 30.7rem;
      border-radius: 0.625rem;

      &:last-child {
        h4 {
          float: right;
        }

        p {
          text-align: right;
        }

        span,
        a {
          float: left;
        }
      }
    }

    > .ant-col:first-child {
      padding-right: 8rem;
    }

    > .ant-col:last-child {
      padding-left: 8rem;
    }

    > .ant-col > .ant-row > .ant-col {
      padding: 0;

      &.ant-col-15 {
        padding-top: 0.4375rem;
      }
    }

    .ant-col {
      background-color: $color-donut-bg;

      h4 {
        font-size: 0.9375rem;
        margin: 0;
      }

      span {
        display: block;
        font-size: 1.5625rem;
        color: inherit;
        margin-top: -0.4rem;
      }

      p {
        font-size: 0.8125rem;
        margin: 0;
        padding: 0;
        color: $color-donut-p;
        line-height: 1rem;
      }

      a {
        font-size: 0.8125rem;
        color: $color-sme-input-icons;
        display: flex;
        align-items: baseline;
        margin-left: 0.2rem;

        p {
          margin-right: 0.2rem;
        }
      }
    }

    .row-first {
      height: 1.5rem;
    }
    .row-middle {
      height: 3rem;
      padding: 0.8rem 0rem;
    }
    .row-last {
      height: 3rem;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
    }
  }

  .donut-unavailable {
    &__content {
      opacity: 0.1;
    }

    &__message{
      font-size: 2rem;
      color: #000;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
