$color-general-disbursed: #adc4ff;
$color-general-available: #2b64f5;
$color-general-overdue: #ff5f5f;

.charts {
  border-radius: 0.938rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: all 0.5s ease-in-out;

  &__column {
    border-radius: 0.938rem;
    position: relative;
    overflow: hidden;
    transition: all 1s ease-in;
    flex-direction: column;
    justify-content: flex-end;
    &:not(.charts__column--isEmpty) {
      margin-top: 0.25rem;
    }
    &--disbursed {
      background-color: $color-general-disbursed;
      position: relative;
    }

    &--available {
      background-color: $color-general-available;
    }
    &--overdue {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $color-general-overdue;
      display: flex;
      flex-direction: column;
      border-radius: 0.938rem;
      justify-content: space-between;
    }
    &--hidden {
      display: none;
    }
  }
}
.chart-title {
  padding: 0.313rem 0.625rem;
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;
  color: #ffffff;

  &__text {
    width: 6rem;
    word-break: break-word;
  }

  &__amount {
    min-width: 2.5rem;
    margin-left: 0.6rem;
  }
}
