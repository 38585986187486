.cookies {
  &__container {
    position: fixed;

    left: 0;
    max-width: 28.125rem;
    box-sizing: border-box;
    padding: 1.875rem;
    z-index: 20;
    border-radius: 0.625rem;

    @include box-shaddow-thin;

    @include screen("phone") {
      width: 100%;
      padding: 1.2rem;
      border-radius: 0;
    }

    &--theme-sme {
      background-color: $color-login-tab-active;
    }

    &--theme-go4rent {
      background-color: $color-background;
      box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16), 0 0.188rem 0.375rem rgba(0, 0, 0, 0.23);

      .cookies__text-wrapper p a {
        color: $color-g4r-red-shade;
      }
    }
  }

  &__button {
    cursor: pointer;
    border: 0.063rem solid;

    @include screen("phone") {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text-wrapper {
    margin-bottom: 20px;
    p {
      margin: 0;
    }
  }
}
