.input-search-wrapper {
  margin-right: 2rem;

  .ant-input-suffix span {
    color: $color-sme-input-icons;
  }

  .ant-input {
    color: $color-txt-black;
  }
}

.ant-input-affix-wrapper {
  border-top: unset;
  border-right: unset;
  border-left: unset;

  &:hover {
    border-color: $color-input-border-not-active;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-labels-active;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-labels-active;
  }

  &::placeholder {
    color: $color-labels-active;
  }
}
