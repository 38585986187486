.upload-invoices-wrapper {
  margin-bottom: 0.625rem;
}

.uploadInvoices {
  &.button {
    padding: 1rem 1.875rem 1rem 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      padding: 0;
    }
  }

  bottom: 1.2rem;
  font-size: 0.9375rem;
  background-image: url("./../../../images/icon_download.svg");
  background-repeat: no-repeat;
  background-position: right 1.6rem center;
  float: right;

  &:hover,
  &:active,
  &:focus {
    background-image: url("./../../../images/icon_download_active.svg");
    background-repeat: no-repeat;
    background-position: right 1.6rem center;
  }

  a {
    margin-top: -0.125rem;
    display: block;
  }
}

.private-content-wrapper {
  overflow: inherit;
  padding-top: 2.3rem;
}

.ant-layout.ant-layout-has-sider > .ant-layout.private-content-wrapper {
  overflow: inherit;
}

.main {
  width: 97.1875rem;
  min-width: 97.1875rem;
  margin: 0 auto;
}

.no-contracts {
  height: 100%;

  &__block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .ant-col-22 {
    flex: 0 0 94%;
    max-width: 94%;
  }
}
