.basicContractDetails {
  p {
    min-height: 1.625rem;
  }
}

.factoring.contractListCollapse {
  .basicContractDetails {
    padding-bottom: 0.5rem;
    padding-right: 2.1rem;

    .late-fee {
      margin-bottom: 0.5rem;
    }
  }
}

.loan {
  &__view-collaterals {
    margin-left: 6.3rem;
  }

  .grayBg.active-status {
    background-color: $color-contract-submitted-bg;
  }

  &.contractListCollapse {
    .buttonContainer {
      text-align: center;
      margin-top: 1rem;

      button {
        border-color: $color-cancel-button;
      }
    }

    .history,
    .history:focus {
      background-color: $color-switch-circle;
      border-color: $color-switch-circle;
    }

    .basicContractDetails {
      padding-right: 2.1rem;
    }
  }
}
