.contract-files {
  padding: 1.3125rem 2.1875rem 2.5rem;
  padding-top: 2rem;
  width: 100%;
  height: 20rem;
  border-radius: 0.625rem;
  overflow: hidden;
  @include box-shadow-donut-thin;
  background-color: $color-background;

  &__header {
    display: flex;
    justify-content: space-between;

    .folder-icon {
      font-size: 1.938rem;
      color: $color-contract-folder-icon;
    }
  }

  &__list {
    height: 7.1rem;
    margin-top: 2.4rem;

    .ant-empty {
      margin-top: unset;

      .ant-empty-description {
        background: transparent;
        line-height: initial;
        font-size: inherit;
      }
    }

    .ant-list,
    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-row {
      display: block;
    }

    .ant-list-pagination {
      margin-top: unset;
      text-align: center;
    }

    p {
      height: 2.1rem;
      line-height: 2.1rem;
      font-size: 0.938rem;
      margin-bottom: 0.25rem;
      background: $color-contract-file-bg;
      border-radius: 1.5625rem;
      padding: 0 1.313rem;
      display: flex;
      justify-content: center;

      span:first-child {
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span:last-child:not(.anticon) {
        color: $color-labels-txt;
      }
    }

    .download-icon {
      margin-left: 1rem;
      font-size: 0.938rem;
      color: $color-sme-blue;
    }
  }
}

.filesModal {
  .ant-modal-body {
    .first-row-padding {
      padding-top: 1.875rem;
    }

    p {
      font-size: 1.1625rem;
      color: $color-txt-black;
    }

    .ant-form-item-explain {
      color: $color-error;
      font-size: 0.8125rem;
    }
  }
  .ant-list-bordered {
    border: unset;
  }

  li {
    display: flex;

    span {
      color: $color-sme-blue;
    }
  }

  &.modal {
    .ant-modal-footer {
      .ant-btn:first-child {
        margin-left: 6.6rem;
      }
      .ant-btn:last-child {
        margin-right: 6.6rem;
      }
    }
  }
}
