.input {
  &:focus,
  &:not(.ant-input-affix-wrapper):not([value=""]) {
    &:not([disabled]) {
      border-bottom-color: $color-labels-active;
    }
    color: $color-labels-active;
    outline: none;
    box-shadow: none;
  }

  &.ant-input-disabled,
  &.ant-input[disabled]:hover {
    border-color: $color-input-border-not-active;
  }

  @include default-bottom-border;

  &::placeholder {
    color: $color-txt-black;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-labels-active;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-labels-active;
  }

  &::placeholder {
    color: $color-labels-active;
  }

  &[disabled] {
    background-color: unset;
  }

  &.ant-input-affix-wrapper {
    padding-left: 0;

    &.ant-input-affix-wrapper-focused {
      border-bottom-color: $color-labels-active;
    }

    &.ant-input-affix-wrapper-disabled {
      background-color: unset;
    }

    input.ant-input {
      border-bottom: 0;

      &:focus,
      &:not([value=""]) {
        border-bottom-color: $color-labels-active;
        color: $color-labels-active;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.input--active {
  & span.input.ant-input-affix-wrapper {
    border-bottom-color: $color-labels-active;
  }
}

