.modal-list-select {
  @include screen("tablet") {
    top: 2rem;
  }

  @include screen("phone") {
    top: 0;
  }

  &__list-item {
    cursor: pointer;
    border-color: $color-border-list;

    &--active {
      background-color: $color-sme-blue-shade-40;
    }

    &--visited {
      background-color: $color-sme-blue-shade-10;

      & .ant-list-item-meta-avatar img {
        filter: grayscale(50%) opacity(50%);
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-avatar {
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
    }
  }

  &__title {
    margin: 0;

    &--visited {
      color: $color-txt-light;
    }
  }

  &__heading {
    margin-left: 0;
  }

  &__title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__success-icon {
    color: $color-sme-blue;
    font-size: 1.8rem;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out opacity;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &--visited {
      color: $color-sme-blue-shade-40;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  .ant-modal-content {
    max-width: 100%;
    .ant-modal-body {
      padding: 1.5rem;
      overflow-y: auto;
      max-height: calc(100vh - 13rem);
    }
  }

  .ant-modal-close-x {
    display: block;
  }

  .ant-modal-footer {
    padding: 0.5rem 1.5rem 1rem;

    &::after {
      content: none;
    }
  }
}
