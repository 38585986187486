.icon-small {
  width: 1.375rem;
  height: 1.375rem;
}
.icon-big {
  width: 2.125rem;
  height: 2.125rem;
}
.icon-responsive {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
