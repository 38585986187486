.ant-picker {
  border: 0 none;

  .ant-picker-input {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
  }

  &.ant-picker-focused .ant-picker-input {
    border-bottom-color: $color-labels-active;
  }

  &.datepicker {
    width: 100%;
  }

  &input {
    color: $color-txt-black;
  }

  &:hover {
    border-bottom-color: $color-txt-black;
  }

  .ant-picker-suffix {
    color: $color-sme-input-icons;
  }
}
