.wave {
  padding: 0;

  h3 {
    padding: 0 0 0 2.5rem;
    margin-bottom: 3.594rem;
  }

  .rechart-wrap {
    width: 100%;
  }
  strong {
    display: inline-block;
    margin-right: 1.3rem;
  }
  .navigation {
    font-size: 0.9375rem;
    padding: 0.7rem;
    background-color: transparent;
    border: none;
    background-image: url(/static/media/icon_arrow.5e1660f6.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 1.2rem;
    height: 2rem;

    &.left {
      transform: rotate(90deg);
    }

    &.right {
      transform: rotate(90deg) scale(1, -1);
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  .ant-table-wrapper {
    padding-right: 4rem;
    padding-left: 0;
    padding-top: 3.4375rem;
    padding-bottom: 2rem;

    margin: 0;
  }

  padding-top: 2rem;
  .intervalActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex: 1;
    justify-content: flex-end;
    .ant-btn-link {
      background-color: "transparent";
      transition: background-color 0.3s linear;
      &:first-child {
        margin-right: 1rem;
      }
      height: 1.6875rem;
      font-size: 0.8125rem;
      color: $color-sme-input-icons;
      padding: 0.3rem 1rem;

      border: unset;
      font-size: 0.8125rem;

      &.active {
        background-color: $color-button-add-buyer;
      }
    }
    span {
      margin-top: unset;
    }
  }
  .waveChartControls {
    position: relative;
    strong {
      text-transform: capitalize;
    }
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .waveChartControlsWrapper {
    position: absolute;
    left: 8.4375rem;
  }
  .rechart-container {
    height: 16.5rem;
  }

  .recharts-tooltip-wrapper:empty {
    display: none;
  }

  .recharts-custom-tooltip-content p {
    padding: 0;
    margin: 0;
    font-size: 0.6875rem;
    line-height: 1rem;
    color: $color-txt-black;
  }
  .chart-no-data {
    pointer-events: none;
    .ant-btn-link {
      background-color: transparent;
      opacity: 0.2;
    }
  }
}
