.phone-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ant-form-item {
    min-width: auto;

    div.ant-col.ant-form-item-label {
      padding: 0;
    }
  }

  .ant-input-group .ant-input-group-addon .ant-select-show-arrow {
    &.phone-code {
      width: 5.625rem;

      input {
        border-bottom: unset;
      }
    }
  }

  .ant-select {
    margin-right: 0.625rem;
    border-bottom: 0.0625rem solid $color-input-border-not-active;
    height: 2.0625rem;
    color: $color-txt-black;

    &.phoneCode {
      height: 2rem;
    }

    .ant-select-selection-placeholder {
      color: $color-txt-black;
      opacity: 1;
    }
  }

  .ant-input-group-addon {
    border: 0 none;
    background: transparent;
    padding-top: 0.125rem;
    padding-right: 0;
  }

  input {
    @include default-bottom-border;
    font-size: 0.9375rem;

    &:focus {
      border-bottom-color: $color-labels-active;
    }

    &.ant-input {
      padding: 4px 11px;
    }

    &::placeholder {
      color: $color-txt-black;
    }

    color: $color-txt-black;
  }

  .ant-form-item {
    &.ant-form-item-has-error {
      .ant-input {
        color: $color-error;
        border-color: $color-error;
      }

      label {
        color: $color-error;
      }
    }
  }

  &.ant-form-item-has-error {
    .phone-code {
      border-color: $color-error;
      .ant-select-selection-item {
        color: $color-error;
      }
    }
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-labels-active;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-labels-active;
  }

  &::placeholder {
    color: $color-labels-active;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $color-input-border-not-active;
}

.ant-select-selection-item {
  color: $color-txt-black;
  opacity: 1;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 1;
}
