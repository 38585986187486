.ant-modal-content {
  width: 48.125rem;

  .ant-input-affix-wrapper {
    box-shadow: unset;
    -webkit-box-shadow: unset;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-bottom-color: $color-txt-black;
  }

  .ant-modal-footer {
    padding-bottom: 5.75rem;

    .ant-btn {
      &:first-child {
        margin-left: 9.625rem;
      }
    }
  }

  .ant-modal-footer {
    border-top: 0 none;
    padding-bottom: 3.75rem;

    .ant-btn {
      &:first-child {
        float: left;
        margin-left: 6rem;
      }

      &.ant-btn-primary {
        margin-right: 6.25rem;
      }
    }

    &::after {
      display: block;
      width: 40rem;
      height: 0.875rem;
      content: "";
      background-color: $color-upload-error;
      position: absolute;
      bottom: 0;
      left: 4.0625rem;
      border-top-left-radius: 20rem 0.875rem;
      border-top-right-radius: 20rem 0.875rem;
      opacity: 0.1;
    }
  }
}

.modal {
  .ant-modal-body {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  &.large {
    .ant-modal-body {
      padding: 5rem 7rem;
    }
  }

  .first-row-padding {
    padding-top: 1.875rem;
  }

  p {
    font-size: 1.1625rem;
    color: $color-txt-black;
  }

  .ant-form-item-explain {
    color: $color-error;
    font-size: 0.8125rem;
  }

  .ant-modal-footer {
    .ant-btn:last-child {
      margin-right: 6rem;
    }
  }

  &.medium {
    .ant-btn:first-child {
      margin-left: 6.6rem;
    }

    .ant-btn:last-child {
      margin-right: 6.6rem;
    }
  }

  &.blue {
    .ant-modal-footer::after {
      background-color: $color-sme-blue;
    }
  }
  &.grey {
    .ant-modal-footer::after {
      background-color: $color-input-border-not-active;
    }
  }

  .ant-form-item-has-error {
    &.currency-number,
    &.currency-number::placeholder,
    &.date-picker input,
    &.date-picker input::placeholder,
    .ant-picker-input,
    .ant-input,
    .ant-input::placeholder,
    .ant-select-selection-placeholder,
    .ant-select,
    .ant-input-number-input,
    .ant-input-number-input::placeholder,
    input,
    input::placeholder,
    .ant-input-suffix span,
    .ant-select-selection-item {
      color: $color-error;
      border-color: $color-error;
    }
  }

  .disabled {
    display: none;
  }

  .empty {
    p {
      color: $color-no-items;
    }
  }
}
