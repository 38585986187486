.ant-upload.ant-upload-drag {
  p.ant-upload-hint {
    color: $color-txt-black;
  }

  p.ant-upload-text {
    color: $color-sme-input-icons;
  }
}

.ant-upload.ant-upload-drag {
  .ant-upload {
    padding-top: unset;
  }
}
