.top-menu {
  margin-bottom: 0.625rem;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-left: 2rem;
  ul {
    display: flex;
    padding: 0;
  }
  li {
    list-style-type: none;
  }

  a {
    font-size: 0.9375rem;
    margin-right: 1.75rem;
    padding: 0 0.5rem 0.75rem 0.5rem;
    color: $color-donut-p;

    &.nav-active,
    &:hover {
      color: $color-labels-active;
      border-bottom: 0.15rem solid #2b64f5;

      &::before {
        background-color: $color-labels-active;
      }
    }
  }
}
