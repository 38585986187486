.ant-affix {
  width: 100% !important;
}

.ant-pagination-options-size-changer {
  .ant-select-arrow + div > div > div {
    top: 0.625rem !important;
  }

  .ant-select-dropdown {
    left: 0 !important;
  }
}

.ant-pagination-options {
  .ant-select-dropdown {
    width: 6.0625rem !important;
  }
}

.mainSpinner  {
  top: 650% !important;
}

.hidden {
  display: none !important;
}

.ant-table-wrapper tr.ant-table-expanded-row .notExpanded {
  height: 0 !important; // Overwrite inline style for height animation
}
