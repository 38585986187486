.ant-table-wrapper.contract-sign-table {
  padding: 2rem 0;

  .contract-sign-table__disabled-button {
    pointer-events: none;
  }

  .contract-sign-table__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  tr {
    td {
      padding: 1rem;
      cursor: default;

      &:first-child {
        max-width: 10.5rem;
      }

      &:nth-child(2) {
        max-width: 7rem;
      }

      &:nth-child(3) {
        max-width: 8rem;
      }

      &:nth-child(4) {
        max-width: 7.625rem;
      }
    }
  }

  .contract-sign-table__actions {
    display: flex;
    align-items: center;
  }

  .contract-sign-table__download-icon {
    color: $color-sme-blue;
    font-size: 1.25rem;
    margin-left: auto;
  }
}

.contract-sign-table__tooltip {
  max-width: 11rem;
  width: 100%;

  .ant-tooltip-arrow {
    right: 0.625rem;
    display: block;

    &::before {
      background-color: $color-sme-input-icons;
    }
  }
  .ant-tooltip-inner {
    font-size: 0.8125rem;
    border: 0.063rem solid $color-sme-input-icons;
    color: $color-sme-input-icons;
  }
}
