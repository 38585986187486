.loading-spin {
  &__loading-screen {
    padding: 6.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__loading-indicator {
    margin-bottom: 2rem;
    font-size: 3rem;
    color: $color-sme-blue;
  }
}
