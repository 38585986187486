.invoice-verification {
  &.publicLayout {
    background-image: none;

    header {
      padding-top: 4.125rem;
      background: $color-sme-blue;

      p {
        display: none;
      }

      .headerContent {
        padding-bottom: 2.125rem;
      }
    }
  }
}

.invoice-verification-confirmation-wrapper {
  h1 {
    margin-top: 3.5625rem;
    margin-left: unset;
  }

  > p {
    font-size: 0.9375rem;
  }

  h1,
  > p {
    padding-left: 4.375rem;
  }

  .form {
    input {
      &::placeholder {
        color: $color-input-border-not-active;
      }
    }

    .authority {
      span {
        font-size: 0.875rem;
      }
    }
  }

  .invoice-verification-confirmation {
    @include box-shaddow-thin;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    padding-bottom: 6.25rem;
    padding-top: 2.375rem;
    margin-top: 3.75rem;

    .invoice-verification-confirmation__header {
      margin-bottom: 2.3125rem;
      margin-top: 0.625rem;
      color: $color-txt-black-notfound;
    }

    .invoice-verification-confirmation__actions {
      .ant-col:first-child {
        padding-right: 0.625rem;
      }

      .invoice-verification-confirmation__actions__note {
        text-align: center;
        font-size: 0.6875rem;
        color: $color-input-border-not-active;
        margin-top: 1.25rem;
      }
    }

    .invoice-verification-confirmation__submit {
      padding-bottom: 1.875rem;

      .button {
        margin-top: 1.75rem;
      }
    }

    .invoice-verification-confirmation__actions,
    .invoice-verification-confirmation__submit {
      .ant-col:first-child {
        padding-right: 0.625rem;
      }

      .ant-btn {
        width: 100%;
      }
    }

    .grid {
      padding-left: unset;
      padding-right: unset;
      padding-top: 1rem;
      padding-bottom: 3.9rem;

      &.empty {
        .ant-table-content {
          .invoiceNumber {
            width: auto;
          }
        }
      }

      .edit {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.5rem;
        background-color: $color-sme-input-icons;
        -webkit-mask-image: url("./../../icons/icon_edit.svg");
        mask-image: url("./../../icons/icon_edit.svg");
        mask-repeat: no-repeat;
        border-radius: initial;
        padding-right: unset;
        padding-left: unset;
      }

      .ant-table-content {
        .invoiceNumber,
        .totalAmount,
        .dueDate {
          width: 7rem;
        }

        .invoiceStatusTxt {
          width: 5rem;
        }

        .text {
          width: 14rem;
          padding-right: 1.25rem;

          input {
            width: 80%;
          }

          .edit {
            margin-left: 1.75rem;
          }
        }

        th.status-column {
          padding-right: unset;
        }

        td.status-column {
          &.status-empty::after {
            border-right-color: $color-background-block;
          }
          &.status-accept::after {
            border-right-color: $color-txt-confirmed;
          }
          &.status-reject::after {
            border-right-color: $color-border-invoice-status-overdue;
          }
        }
      }
    }
  }
}
