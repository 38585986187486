$table-row-right-border-color-status-paid : #84D684;
$table-row-right-border-color-status-overdue : #FF6464;
$table-row-right-border-color-status-unpaid : #FFBC79;
$table-row-right-border-color-status-partially-paid : #84D6BA;
$arrow-icon-color: #2B64F5;


.table-wrapper {
    background-color: inherit;

    // RESET ALL ANT CUSTOM STYLES HERE
    .ant-table-wrapper {
      padding: unset;
      margin: 2.5rem 0;
    }
    .ant-table-expand-icon-col, .ant-table-row-expand-icon-cell {
      display: none;
    }
    .ant-table-expanded-row > .ant-table-cell {
      border-bottom: none;
    }
    .remove-default-ant-cell-padding .ant-table-cell {
      padding: 0;
    }
    .ant-table thead > tr > th {
      padding: 1rem 0;
    }
    .ant-table-footer {
      background-color: $color-background;
      display: flex;
      justify-content: flex-end;
      padding: 2rem 0 0 1rem;
    }

    .arrowIconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      color: $arrow-icon-color;

      span {
        transition: transform 0.3s ease-out;

        &.rotateDown {
          transform: rotateZ(90deg);
        }
      }

      svg {
        font-size: 0.625rem;
      }
    }

    .infoIconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      span > svg {
        background-color: $bg-info-svg;
        color: $color-white-txt;
        border-radius: 50%;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    .table-cell {
      &--arrow {
        // TODO when all tables will be merged to single style, remove this !important line
        border: solid transparent 1px !important;
        padding: 0;
      }
    }

    .table-row-right-border {
      position: relative;

      &:after {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: calc(100% + 0.12rem);
          right: 0;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }

        &.paid::after {
          background-color: $table-row-right-border-color-status-paid;
        }
        &.unpaid::after {
          background-color: $table-row-right-border-color-status-unpaid;
        }
        &.overdue::after {
          background-color: $table-row-right-border-color-status-overdue;
        }
        &.partially-paid::after {
          background-color: $table-row-right-border-color-status-partially-paid;
        }
      }
    

    .table-row-expanded {
      max-height: 0;
      transition: max-height 0.3s ease-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
  
      &.expanded {
        max-height: 4rem;
      }

      .table-expanded-row-right-border {
        &:after {
          display: block;
          content: "";
          position: absolute;
          width: 0.5rem;
          height: calc(100% + 0.5rem);
          right: 0;
          top: -0.5rem;
          border-bottom-right-radius: 0.5rem;
        }

        &.submitted::after {
          background-color: $color-border-invoice-status-submitted;
        }
        &.paid::after {
          background-color: $table-row-right-border-color-status-paid;
        }
        &.unpaid::after {
          background-color: $table-row-right-border-color-status-unpaid;
        }
        &.overdue::after {
          background-color: $table-row-right-border-color-status-overdue;
        }
        &.rejected::after {
          background-color: $color-border-invoice-status-rejected;
        }
        &.financed::after {
          background-color: $color-border-invoice-status-financed;
        }
        &.partially-paid::after {
          background-color: $table-row-right-border-color-status-partially-paid;
        }
        &.closed::after {
          background-color: $color-border-invoice-status-closed;
        }
        &.transferred::after {
          background-color: $color-border-invoice-status-transferred;
        }
      }

      // Customize for different tables expanded row
      &.expanded-loan-bills-row {
        margin-right: 5em;
        margin-left: 30rem;

        .linkButton {
          width: 2rem;
        }
        div.downloadBtnWrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          align-self: center;
          > button.downloadBtn {
            display: inline-block;
            padding: unset;
            width: 1rem;
            height: 0.9375rem;
            overflow: hidden;
            text-indent: 9999rem;
            background-image: url("../../../icons/icon_download.svg");
            background-repeat: no-repeat;
            background-position: top center;
            transform: rotate(180deg);
            margin-right: 1rem;
          }
        }
        a {
          color: inherit;
        }

        table {
          padding-top: 1rem;

          tr td {
            color: $color-secondary-text;
          }
        }
      }
    }

    .first-column {
      padding-left: 0 !important;
    }
}
