.Bills-page {
  .BillListFilter {
    padding: 2rem 4rem 4rem 4rem;
    padding: 2.2rem 5.25rem 3rem 5.25rem;

    .active-true {
      .filterDataValue {
        color: $color-txt-black;
      }
    }

    .ant-select-selection-item {
      border-bottom: unset;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 0.0625rem solid $color-input-border-not-active;
    }

    .checboxFilters {
      margin-bottom: 1.25rem;

      label {
        min-width: 15.95rem;
      }
    }
  }
  .ContractSelector {
    padding: $default-section-padding;
    h4 {
      display: none;
    }
  }

  .filterDataValue {
    color: $color-sme-input-icons;
    float: right;
  }
  ::placeholder {
    color: $color-labels-active;
  }
  .ReportListFilter {
    label {
      font-size: 0.6875rem;
    }

    .ant-select-selector,
    .ant-picker,
    .ant-picker-input {
      padding: 0;
      width: 100%;
    }

    input {
      font-size: 0.9375rem;
    }
    .ant-select-single {
      width: 96%;
    }

    .ant-select-selector {
      margin-top: 0.113rem;
    }

    .ant-input-search {
      padding: 0;
    }

    .ant-picker,
    .ant-input-search {
      padding-top: 0.2rem;
    }

    .ant-picker .ant-picker-input,
    .ant-form-item {
      border-bottom: 0.0625rem solid $color-input-border-not-active;
    }

    .ant-picker .ant-picker-input,
    .ant-select-selection-item,
    .ant-form-item,
    .input-seach,
    .ant-select-single {
      margin-right: 2rem;
    }

    input {
      margin: 0.2rem 0;
    }
  }

  .checboxFilters {
    label {
      font-size: 0.9375rem;
      color: $color-txt-black;
      border: 0.0625rem $color-report-checkbox-border solid;
      border-radius: 2rem;
      padding: 0.75rem 1.4rem;
      padding-top: 0.7rem;
      margin-left: 0.5rem;
      cursor: pointer;
      min-width: 12.926rem;
      display: inline-block;

      &:hover {
        border-color: transparent;
        background-color: $color-report-checkbox-checked-bg;
      }

      &:first-child {
        margin-left: 0;
      }

      &.active-true {
        background-color: $color-report-checkbox-checked-bg;
        border-color: $color-report-checkbox-checked-bg;

        &:hover {
          background-color: transparent;
          border-color: $color-report-checkbox-border;
        }
      }
    }
    input {
      display: none;
    }
  }

  .ant-table-wrapper {
    padding-top: unset;
  }

  .block {
    margin-top: 0.9375rem;
  }

  .billList {
    .innerAmount {
      position: relative;
    }

    margin: 0;

    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;
  }

  .BillTable {
    padding: 3rem 4rem 3rem 1rem;

    td.ant-table-row-expand-icon-cell,
    th.ant-table-row-expand-icon-cell {
      border: none;
      width: $table-table-wrapper-padding;
      max-width: $table-table-wrapper-padding;
      min-width: $table-table-wrapper-padding;
    }
    .invoiceAmount {
      width: 6rem;
    }

    .innerItemDate {
      padding-left: 1.25rem;
    }

    .paid,
    .unpaid {
      width: 6rem;
    }

    .downloadBtn {
      display: inline-block;
      width: 1rem;
      height: 0.9375rem;
      overflow: hidden;
      text-indent: 9999rem;
      background-image: url(/static/media/icon_download.659a9e91.svg);
      background-repeat: no-repeat;
      background-position: top center;
      transform: rotate(180deg);
      margin-right: 1rem;
    }

    .ant-btn-link {
      margin-right: 1rem;
      height: 0.9375rem;
      padding: unset;
      line-height: 1.5715;
      margin-top: 0.125rem;
    }

    .ant-table-cell {
      &.billStatus {
        border-bottom: unset;
        width: 0.4375rem;

        &:after {
          display: block;
          position: absolute;
          width: 0.5rem;
          content: "";
          height: 100%;
          top: -0.0625rem;
          border-right: 0.5rem solid #fff;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }

    .anticon-right,
    .anticon-down {
      font-size: 0.6rem;
    }

    .ant-table-footer {
      padding-right: 0;
      background-color: unset;
    }

    Button {
      float: right;
    }
  }

  .ant-table-wrapper {
    .expand-parent td {
      border-bottom: 0 none;
      border-bottom-right-radius: unset;
    }

    .expand-parent {
      .billStatus:after {
        border-bottom-right-radius: unset;
      }
    }

    .status-Paid:after,
    + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-border-invoice-status-paid;
    }

    .status-Unpaid:after,
    .status-Unpaid + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-contract-overdue-txt;
    }

    .status-Sold:after,
    .status-Writtenoff:after,
    .status-Sold + .ant-table-expanded-row-level-1 td:after,
    .status-Writtenoff + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-is-rejected;
    }

    .expand-parent + .ant-table-expanded-row-level-1 td:after {
      border-top-right-radius: unset;
    }

    .status-Notsubmitted + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-not-submitted;
    }

    .status-Overdue + .ant-table-expanded-row-level-1:after {
      border-right-color: $color-invoice-overdue;
    }

    .status-Verification + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-verification;
    }

    .status-Rejected + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-rejected;
    }

    .status-Submitted + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-submitted;
    }
  }


  .table-expanded {
    height: 4rem;
  }

  .bill-filter {
    .ant-picker-input, .ant-input-search {
      border-bottom: 1px solid #000;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 1px solid #000;
    }
  }
}
