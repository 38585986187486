body .account-container {
  .loginSettings {
    label {
      color: $color-txt-black;
    }
  }
  table {
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;

    th {
      font-weight: 500;
    }
  }

  p {
    font-size: 1.0625rem;
    color: $color-txt-black;
  }

  .accounts {
    th {
      width: 50%;
    }
  }

  .companyOfficials {
    th {
      width: 33.33333333%;

      &:nth-of-type(1) {
        width: 27%;
      }
    }

    p {
      margin-bottom: unset;
      font-size: 0.9375rem;
    }

    margin-bottom: 2rem;
  }

  .CompanyInformation {
    margin-bottom: 1.125rem;
  }

  .company-officials {
    .company-officials__add-new-user {
      float: right;
    }
  }

  .ant-collapse.ant-collapse-icon-position-right .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .PersonalInformation {
    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .name {
      p {
        height: 3.9rem;
      }
    }

    .infoRow {
      padding-bottom: 0.5rem;
    }
  }

  .ant-collapse-content-box {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-top: 2.5rem;
    padding-bottom: 4.375rem;
  }

  .edit {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.25rem;
    margin-top: 0.125rem;
    background-color: $color-sme-input-icons;
    -webkit-mask-image: url("./../icons/icon_edit.svg");
    mask-image: url("./../icons/icon_edit.svg");
    mask-repeat: no-repeat;
    padding: 0;
    border-radius: initial;
  }

  .delete,
  .update {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.625rem;
    background-color: $color-sme-input-icons;
    -webkit-mask-image: url("./../icons/icon_delete.svg");
    mask-image: url("./../icons/icon_delete.svg");
    mask-repeat: no-repeat;
    border-radius: unset;
    padding-right: unset;
  }

  .update {
    -webkit-mask-image: url("./../icons/icon_edit.svg");
    mask-image: url("./../icons/icon_edit.svg");
    padding-left: unset;
  }

  .ant-input {
    width: 90%;
  }

  .ant-alert {
    border: 0 none;
    background: transparent;
    padding-left: 1.25rem;
    margin-top: -0.1rem;
  }

  .ant-alert-success {
    svg,
    .ant-alert-message,
    .ant-alert-icon {
      color: $color-border-invoice-status-paid;
    }
  }

  .ant-alert-warning {
    padding-left: unset;
    svg,
    .ant-alert-message,
    .ant-alert-icon {
      color: $color-border-invoice-status-submitted;
    }
  }

  .ant-alert-icon {
    left: 0;
    margin-top: -0.0625rem;
  }

  .company {
    .edit {
      margin-left: 2.5rem;
    }
  }

  .ant-badge-count {
    color: $color-sme-input-icons;
    background-color: $color-white-txt;
    top: 7.5rem;
    width: 0.625rem;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 1.3125rem;
    font-weight: unset;

    &::before {
      display: inline-block;
      content: "";
      width: 1.8rem;
      height: 1.5rem;
      margin-right: 1.5rem;

      background-color: $color-border-user-menu;
      -webkit-mask-image: url("./../icons/icon_profile.svg");
      mask-image: url("./../icons/icon_profile.svg");
      mask-repeat: no-repeat;
    }

    &.account::before {
      -webkit-mask-image: url("./../icons/icon_profile.svg");
      mask-image: url("./../icons/icon_profile.svg");
    }

    &.company::before {
      -webkit-mask-image: url("./../icons/icon_company.svg");
      mask-image: url("./../icons/icon_company.svg");
    }

    &.settings::before {
      -webkit-mask-image: url("./../icons/icon_settings.svg");
      mask-image: url("./../icons/icon_settings.svg");
    }

    &.legal::before {
      -webkit-mask-image: none;
      mask-image: none;
      background-color: transparent;
    }

    &.legal {
      padding-top: 0.4375rem;
      background-image: url("./../icons/icon_folder.png");
      background-repeat: no-repeat;
      background-position-x: -0.375rem;
    }
  }

  h4 {
    font-size: 1.0625rem;
    font-weight: unset;
    color: $color-labels-txt;
  }

  .downloadBtn {
    display: inline-block;
    width: 1rem;
    height: 0.9375rem;
    overflow: hidden;
    text-indent: 9999rem;
    background-image: url(/static/media/icon_download.659a9e91.svg);
    background-repeat: no-repeat;
    background-position: top center;
    transform: rotate(180deg);
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.editCompany {
  h3 {
    margin-top: 1.25rem;
  }

  .ant-input-group-addon {
    border: 0 none;
    background-color: transparent;
    padding-left: 0;
  }

  .ant-select-selector {
    padding-left: 0;
  }
}

.personal-information-action {
  &.edit-user {
    .description {
      margin-top: 3rem;
      margin-bottom: 0.625rem;
    }
  }

  &.check-password {
    .description {
      margin-bottom: 2.5rem;
    }

    .check-password__reset-password {
      padding: unset;
      height: unset;
      float: right;
      color: $color-sme-blue;
    }
  }

  .description {
    margin-top: 3.75rem;

    .text {
      font-size: 1.0625rem;
    }
  }

  .action {
    margin-bottom: 1.875rem;
  }

  .action {
    .ant-input-suffix {
      position: absolute;
      right: 0;
    }

    .ant-input-affix-wrapper {
      padding: unset;
    }

    input {
      padding: 0.25rem 0.6875rem;
      padding-left: unset;
      font-size: 0.9375rem;
    }
  }

  .ant-checkbox-group {
    margin-top: 1.25rem;

    .ant-checkbox-group-item {
      display: block;
      margin-bottom: 1.25rem;
    }

    .ant-checkbox-disabled + span {
      color: $color-txt-black;
    }

    label {
      color: $color-txt-black;
      font-size: 0.9375rem;
    }
  }

  .ant-modal-footer {
    padding-bottom: 7.375rem;
  }

  label {
    height: 0.6875rem;
    color: $color-labels-txt;
    font-size: 0.8125rem;
  }

  .ant-form-item-label {
    padding-bottom: unset;
  }

  .current {
    p {
      font-size: 0.9375rem;
      color: $color-labels-txt;
    }
  }

  .phone-input .ant-select {
    height: 2rem;
  }

  .ant-select {
    margin-right: 0.625rem;
    border-bottom: 0.0625rem solid $color-input-border-not-active;
  }

  .ant-input-group-addon .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: unset;
  }
}

.newUser.modal {
  h3 {
    margin-top: 2rem;
    color: $color-txt-black;
  }

  p {
    margin-top: 1.25rem;
    color: $color-txt-black;
    font-size: 0.9375rem;
  }

  .ant-input-number {
    width: 100%;
    border: 0 none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select.ant-select-focused {
    border-bottom-color: $color-labels-active;
  }

  label {
    color: $color-labels-txt;
    font-size: 0.8125rem;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding-left: unset;
      height: 1.8125rem;
    }
  }

  .ant-input-group-addon {
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        padding-left: unset;
        height: 1.825rem;
        width: 4.375rem;
      }
    }
  }

  .phoneNumber {
    .ant-form-item-explain {
      padding-left: 6.25rem;
    }
  }

  .ant-input-group {
    input.ant-input {
      padding-left: unset;
    }
  }

  input:not([type="checkbox"]),
  input::placeholder,
  .ant-select-selection-placeholder {
    font-size: 0.9375rem;
    opacity: 1;
  }

  .ant-form-item-label {
    padding-bottom: unset;
    label {
      height: 0.6875rem;
    }
  }
}

.editSmartId,
.editMobileId {
  .description {
    margin-bottom: 3.75rem;
  }
  .ant-form-item:first-child {
    margin-top: -1px;
    padding-right: 0.625rem;

    .ant-select-selector {
      padding-left: unset;
    }

    .ant-select-selection-placeholder {
      color: $color-txt-black;
      font-size: 0.9375rem;
      opacity: 1;
    }
  }

  .ant-input-group-addon {
    padding-left: unset;
  }
}

.editMobileId {
  .ant-form-item:last-child {
    margin-top: -2px;

    .ant-select {
      margin-left: 1px;
    }

    .ant-select-selector {
      width: 74px;
    }

    input {
      margin-top: 1px;
    }
  }
}
